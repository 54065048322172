import React, { useState, useEffect } from 'react';
import { Container, Typography, Paper, Grid, Button,Radio,FormLabel,FormControl, TextField,RadioGroup, FormControlLabel,Box, FormHelperText,Avatar, IconButton,Alert,DialogContentText, CircularProgress,Dialog, DialogTitle, DialogContent,DialogActions} from '@mui/material';
import { styled } from '@mui/system';
import { Email, Edit, CheckCircle, Cancel, PendingActions, Person } from '@mui/icons-material';
import Tick from '../../assets/images/Tick.jpeg'; // Replace with your image path
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate, useLocation } from 'react-router-dom';
import { addDays, isBefore, startOfToday, setHours, setMinutes } from 'date-fns';

import axios from 'axios';
import { config } from '../../config';
import CancelOrderDialog from './CancelReason.js';
 import DeviceEvaluation from '../commonForms/DeviceEvaluation.js';
import {useSelector } from 'react-redux';
import { LocalizationProvider, DatePicker, TimePicker} from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';


const ProfileContainer = styled(Container)({
  marginTop: '20px',
});

const ProfileCard = styled(Paper)({
  padding: '20px',
  textAlign: 'center',
  position: 'relative',
  transition: 'transform 0.3s ease-in-out',
  '&:hover': {
    transform: 'scale(1.05)',
    boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
  },
});

const CancelledOrderCard = styled(Paper)({
  padding: '20px',
  marginTop: '20px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  transition: 'transform 0.3s ease-in-out',
  '&:hover': {
    transform: 'scale(1.02)',
    boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
  },
});

 

const VerificationPendingBox = styled(Box)({
  backgroundColor: '#fff3cd',
  padding: '10px',
  marginTop: '10px',
  borderRadius: '5px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});
const disablePastDates = (date) => {
  return isBefore(date, startOfToday());
};



const getStatusIcon = (status) => {
  switch (status) {
    case 'Completed':
      return <CheckCircle />;
    case 'Failed':
      return <Cancel />;
    case 'Pending':
    default:
      return <PendingActions />;
  }
};

const getStatusColor = (status) => {
  switch (status) {
    case 'Completed':
      return 'green';
    case 'Failed':
      return 'red';
    case 'Pending':
    default:
      return 'orange';
  }
};





const ProfilePage = () => {
const navigate = useNavigate();
const rowDetails3 = useSelector((state) => state.wrapper.rowDetails);
console.log(rowDetails3,"rowDetails3")

const location = useLocation();
const {name,email,PhoneNumber} = location.state || {}
const [userDetails, setUserDetails] = useState([]);
const [finalAmounts, setFinalAmounts] = useState({});
const [loading, setLoading] = useState(false);
const [loadingStates, setLoadingStates] = useState({});
const [cancelDialogOpen, setCancelDialogOpen] = useState(false);
const[OrderCancelled,setOrderCancelled] = useState(false);
const [reportDialogOpen, setReportDialogOpen] = useState(false); // State for report dialog
const [currentPage, setCurrentPage] = useState('profile');
const [OrderID,setOrderID] = useState(null);
const[selectModel,setselectModel] = useState({model:"",brandName:"",ImageURL:"",profile:true});
const[formattedVariant,setformattedVariant] = useState(null);
const [deviceAnswers,setdeviceAnswers] = useState({});
const [answers, setAnswers] = useState({});

const [pickupTimeOption, setPickupTimeOption] = useState('10am to 2pm'); // State for pickup time option
const [formData, setFormData] = useState({pickupTimeOption:null,pickupDate:null})
const [errors, setErrors] = useState({});
const [message, setMessage] = useState('');
const [messageType, setMessageType] = useState(''); 
 const[RescheduleOpen,setRescheduleOpen] = useState(false);
 const[RescheduleID,setRescheduleID] = useState(null);
 const [selectedDate, setSelectedDate] = useState(null);
 const [selectedTime, setSelectedTime] = useState(null);
 const [cancelledOrders, setCancelledOrders] = useState({});

const handlePickupTimeOptionChange = (e) => {
  const value = e.target.value;
  setPickupTimeOption(value);
  setFormData(prevData => ({
    ...prevData,
    pickupTimeOption: value,
  }));
};
 

const  handleRescheduleSubmit = async () => {
  setLoading(true);
  // Basic validation
  const newErrors = {};
   if (!formData.pickupDate) newErrors.pickupDate = 'Pickup date is required';
  if (!formData.pickupTimeOption) newErrors.pickupTimeOption = 'Pickup time is required';
  if (Object.keys(newErrors).length === 0) {
    try {
      const url = RescheduleID ? `${config.Api}userpickup/${RescheduleID}` : config.Api+'userpickup';

    const method = RescheduleID ? 'POST' : 'POST';
    const response = await fetch(url, {
      method: method,
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(formData),
    });

    console.log(formData,"formData")

      if (response.ok) {
        const result = await response.json(); // Parse the JSON response
        console.log(result,"result");
         alert('Order has been rescheduled SuccessFully');
         setRescheduleOpen(false);
         fetchUserDetails();
         setMessageType('success');
         
     
        // Clear form data after successful submission if needed
        setFormData({
          pickupDate: null,
          pickupTimeOption: '10am to 2pm', // Reset to default value
         
        });
      } else {
        // setPickupDetails([]);
        throw new Error('Failed to Reschedule');
      }
    } catch (error) {
      setMessage('Error submitting details. Please try again.');
      setMessageType('error');
     }finally {
      setLoading(false); // Stop loading after processing
    }
  } 
  else {
    setErrors(newErrors);
       setMessageType('error');
    setLoading(false); // Stop loading even if there are validation errors
  }
};


const handleDateSelect = (date) => {
  const utcDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000);

  setSelectedDate(utcDate);
  setFormData(prevData => ({
    ...prevData,
    pickupDate: utcDate,
  }));
  setSelectedTime(null); // Reset time when date changes
};
const handleReportOpen = () => {
  setReportDialogOpen(true); 
};
const handleReportClose = () => {
  setReportDialogOpen(false);
  fetchUserDetails();
}; 

const fetchUserDetails = async (uniqueId) => {
  try {
    const response = await axios.post(`${config.Api}userpickupdetails`, {email,uniqueId});
    // console.log(response.data.records,"response.data.records");
    console.log(response.data?.records,"Proofs",response?.data?.records[0]?.Model,"MODELLL"  );
    console.log(response?.data?.records?.Answers,"response?.data?.records?.Answers")
    setAnswers(response?.data?.records[0]?.Answers);
    setdeviceAnswers(response?.data?.records[0]?.deviceAnswers)
    setselectModel({...selectModel,model:response?.data?.records[0]?.Model,brandName:response?.data?.records[0]?.["Brand Name"],ImageURL:response?.data?.records[0]?.ImageURL});
    // setselectModel({...selectModel,brandName:response?.data?.records[0]?.["Brand Name"]});
    setformattedVariant(response?.data?.records[0]?.formattedVariant);

    // setreportData(proof)
    // setUserDetails1(response1.data);
    setUserDetails(response.data.records); // Assuming response.data is an array
  } catch (error) {
    console.error('Error fetching user details:', error);
    alert('Error fetching user details');
  }
};

  useEffect(() => {
   if (email) {
      fetchUserDetails();
    }
  },[email]);

  const handleShowFinalAmount = async (id) => {
    setLoadingStates((prev) => ({ ...prev, [id]: true }));

    try {
      const response = await axios.post(`${config.Api}get-proof-details/${id}`);
      console.log(response.data[0].finalPrice,"response.data.finalPrice")
      setFinalAmounts((prev) => ({ ...prev, [id]: response.data[0].finalPrice }));
    } catch (error) {
      console.error('Error fetching final amount:', error);
      alert('No Final amount Found for this');
    } finally {
      setLoadingStates((prev) => ({ ...prev, [id]: false }));
    }
};

const handleCancelOrder = (id) => {
setCancelDialogOpen(true);
setOrderID(id);
}
const handleReschedule = (id) => {
   setRescheduleOpen(true);
   setRescheduleID(id)
}

const handleDeviceReport = (id) => {
  fetchUserDetails(id);
  handleReportOpen();
}

const handleCancelClose = () => {
  setCancelDialogOpen(false);
};

const handleCancelConfirm = async (reason) => {
  console.log(OrderID,"OrderID")
  try {
   
    const status = 'Failed'; // Define the status as needed

    const response = await axios.post(`${config.Api}update-status/${OrderID}`, { status, reason });

    if (response.status === 200) {
     alert("Order cancelled successfully");
     setOrderCancelled(true);

       // Update the specific order ID in the cancelledOrders state
       setCancelledOrders(prevCancelledOrders => ({
        ...prevCancelledOrders,
        [OrderID]: true,  // Mark this order as cancelled
      }));
     fetchUserDetails();
      // You can add any additional logic here if needed, like showing a success message to the user
    } else {
      alert("Failed to cancel order");
      setOrderCancelled(false);
        // Update the specific order ID in the cancelledOrders state
        setCancelledOrders(prevCancelledOrders => ({
          ...prevCancelledOrders,
          [OrderID]: true,  // Mark this order as cancelled
        }));
      fetchUserDetails();

      // You can add any additional logic here if needed, like showing an error message to the user
    }
  } catch (error) {
    console.error("Error cancelling order:", error);
    // Handle error appropriately, maybe show an error message to the user
  }

  setCancelDialogOpen(false);
};

  return (

    // {currentPage === "profile" && (

    // )
    <>

{message && (
            <Alert severity={messageType} sx={{ mb: 2 }}>
              {message}
            </Alert>
          )}
    <ProfileContainer maxWidth="lg">
      <IconButton onClick={() => navigate("/", { state: { name: userDetails?.[0]?.Name, email: userDetails?.[0]?.Email } })}>
        <Button startIcon={<ArrowBackIcon />}>Go back</Button>
      </IconButton>
      {
       userDetails ? (
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            {/* Enhanced ProfileCard with Avatar */}
            <ProfileCard elevation={3}>
              <Avatar sx={{ width: 80, height: 80, margin: 'auto' }}>
                <Person fontSize="large" />
              </Avatar>
              <Typography variant="h6" sx={{ mt: 2 }}>{userDetails?.[0]?.Name}</Typography>
              <Typography variant="body1">{userDetails?.[0]?.PhoneNumber}</Typography>
              <Typography variant="body2">{userDetails?.[0]?.Email}</Typography>
             
            </ProfileCard>
          </Grid>
          <Grid item xs={12} md={8}>
            {userDetails.map((user, id) => (
              <>
      <CancelledOrderCard key={id} elevation={3}>
                 <Box display="flex" alignItems="center">
                  <Avatar
                    sx={{ backgroundColor: getStatusColor(user.Status), marginRight: '10px' }}
                  >
                    {getStatusIcon(user.Status)}
                  </Avatar>
                  <Box>
                    <Typography variant="body1" fontWeight={"bold"} color={user.Status === "Failed" ? "error" : user.Status === null ? "orange" : user.Status === "Completed" ? "green" : ""}>Pickup {user.Status || "pending"}</Typography>
                    <Typography variant="body2">{user["Pickup Date"]}</Typography>
                  </Box>
 
                </Box>

                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 2 }}>
               <Box
                sx={{
                  width: 80,
                  height: 80,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderRadius: '8px',
                  overflow: 'hidden',
                  border: '1px solid #ddd',
                }}
              >
                <img
                  src={user?.ImageURL|| 'https://via.placeholder.com/80'}
                  alt={`${user["Brand Name"]} ${user.Model}`}
                  style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                />
              </Box>
  
  {/* Text Content */}


                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                    <Typography variant="body1" sx={{ fontWeight: 'bold', mb: 0.5 }}>
                      {user["Brand Name"]} {user.Model}
                    </Typography>
                    <Typography variant="body2" sx={{ color: 'text.secondary', mb: 0.5 }}>
                      {user.formattedVariant}
                    </Typography>
                    <Typography variant="body2" sx={{ color: 'text.secondary', mb: 0.5 }}>
                      Order ID: {user.ID}
                    </Typography>
                    {/* <Link href="#" sx={{ color: 'primary.main', fontWeight: 'bold', textDecoration: 'none', '&:hover': { textDecoration: 'underline' } }}>
                      View order details
                    </Link> */}
                    {/* <p>Note: Final Sell Amount Will be available in Your Mail After the Conversation from our agent</p> */}
                  </Box>
             </Box>
             <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}  >
                    <Typography variant="p" sx={{ fontWeight: 'bold', color: 'red' }}>
                      Sell Amount ₹{user.QuotedPrice}
                    </Typography>
                 
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }} >
                     {/* <Button variant="outlined" size="small" onClick={() => handleShowFinalAmount(user.ID)}>
                      Show Final Amount
                    </Button> */}
                    {/* {loadingStates[user.ID] && <CircularProgress size={20} />}
                      {finalAmounts[user.ID] && (
                       <Typography variant="p" sx={{ fontWeight: 'bold', color: 'red' }}>
                          Final Amount   ₹{finalAmounts[user.ID]}
                        </Typography>
                      )} */}
                            

                            <Box sx={{ display: 'grid', alignItems: 'center', gap: 2 }}>

{ 
  
  <Button
  disabled={cancelledOrders[user.ID] || false}  // Disable only if this specific order is cancelled
  variant="contained"
  className={cancelledOrders[user.ID] ? "disable-cancel-order-button" : "cancel-order-button"}
  color="error"
  onClick={() => handleCancelOrder(user.ID)}
>
  {cancelledOrders[user.ID] ? "Order Cancelled" : "Cancel Order"}
</Button>
  
  
  }

  {  <Button variant="contained" color="warning"  onClick={() => handleReschedule(user.ID)}>
    Reschedule
  </Button>}
  <Button variant="contained" color="primary" onClick={() => handleDeviceReport(user?.ID)}>
    DeviceReport
  </Button>

  <CancelOrderDialog open={cancelDialogOpen} onClose={handleCancelClose} onConfirm={() => handleCancelConfirm()} />

  </Box>
</Box>

                                  <Dialog open={reportDialogOpen} onClose={handleReportClose} maxWidth="sm" fullWidth>
            <DialogTitle>Device Evaluation</DialogTitle>
            <DialogContent>
              <DeviceEvaluation selectedModel={selectModel}  formattedVariant={formattedVariant} answers={answers} deviceAnswers={deviceAnswers}    />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleReportClose} color="primary">Close</Button>
            </DialogActions>
          </Dialog>

         


          
    
                  </CancelledOrderCard>




          
           
              </>
            ))}
          </Grid>
        </Grid>
      ) : (
        <Typography>No Records Found</Typography>
      )}
    </ProfileContainer>


{/* // Reschedule */}


 
 
<Dialog open={RescheduleOpen} onClose={() =>setRescheduleOpen(false)}>
  <DialogTitle>Reschedule Order</DialogTitle>
  <DialogContent>
    <DialogContentText>
      Please select the Time Slot and Date For Reschedule the Order:
    </DialogContentText>
    <Grid item xs={12}>
        <FormControl fullWidth error={!!errors.pickupDate}>

          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Pickup Date"
              value={formData.pickupDate}
              onChange={handleDateSelect}
              minDate={new Date()}
              renderInput={(params) => <TextField {...params} fullWidth />}
              shouldDisableDate={disablePastDates}

            />
          </LocalizationProvider>
          <FormHelperText>{errors.pickupDate}</FormHelperText>
          </FormControl>

          </Grid>
     
          
          <Grid item xs={12}>
          <FormControl component="fieldset">
            <FormLabel component="legend">Pickup Time</FormLabel>
            <RadioGroup row value={pickupTimeOption} onChange={handlePickupTimeOptionChange}>
              <FormControlLabel value="10am to 2pm" control={<Radio />} label="10am to 2pm" />
              <FormControlLabel value="2pm to 6pm" control={<Radio />} label="2pm to 6pm" />
            </RadioGroup>
          </FormControl>
        </Grid>
          
          
  </DialogContent>
  <DialogActions>
     <Button onClick={handleRescheduleSubmit} color="secondary">Reschedule</Button>
  </DialogActions>
</Dialog>
</>
 
  
  );
};

export default ProfilePage;
